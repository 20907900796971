import * as types from './types';
import { updateObject } from './utility';

const initialState = {
  email: '',
  response: []
}

export default function(state = initialState, action) {
  switch(action.type) {
    case types.SAVE_EMAIL:
      return updateObject(state, { email: action.email });
    case types.SAVE_RESPONSE_QUESTION_ONE:
      return updateObject(state, { response: action.response });
    case types.SAVE_RESPONSE_QUESTION_TWO:
      return updateObject(state, { response: action.response });
    case types.SAVE_RESPONSE_QUESTION_THREE:
      return updateObject(state, { response: action.response });
    case types.SAVE_RESPONSE_QUESTION_FOUR:
      return updateObject(state, { response: action.response });
    case types.SAVE_RESPONSE_QUESTION_FIVE:
      return updateObject(state, { response: action.response });
    default:
      return state;
  }
}
