// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-finish-js": () => import("/opt/build/repo/src/pages/finish.js" /* webpackChunkName: "component---src-pages-finish-js" */),
  "component---src-pages-five-js": () => import("/opt/build/repo/src/pages/five.js" /* webpackChunkName: "component---src-pages-five-js" */),
  "component---src-pages-four-js": () => import("/opt/build/repo/src/pages/four.js" /* webpackChunkName: "component---src-pages-four-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-one-js": () => import("/opt/build/repo/src/pages/one.js" /* webpackChunkName: "component---src-pages-one-js" */),
  "component---src-pages-three-js": () => import("/opt/build/repo/src/pages/three.js" /* webpackChunkName: "component---src-pages-three-js" */),
  "component---src-pages-two-js": () => import("/opt/build/repo/src/pages/two.js" /* webpackChunkName: "component---src-pages-two-js" */)
}

