module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Helvetica Neue Bold"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Go Quiz","short_name":"gok","start_url":"/","background_color":"#3ED206","theme_color":"#3ED206","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":true,"firestore":false,"storage":false,"messaging":false,"functions":true}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
